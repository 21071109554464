<script setup>
import BaseButton from "@/app/components/ui/BaseButton.vue"
import BaseDialog from "@/app/components/ui/BaseDialog.vue"
import BaseSelect from "@/app/components/ui/BaseSelect.vue"
import BaseTextField from "@/app/components/ui/BaseTextField.vue"
import { useConfig } from "@/app/composables/useConfig"
import { useI18n } from "@/app/composables/useI18n"
import { toast } from "@/app/utils/notification"
import { api } from "@/services"
import WalletAddress from "@/user-account/components/WalletAddress.vue"
import WalletSelect from "@/user-account/components/WalletSelect.vue"
import { useUserService } from "@/user-account/composables/useUserService.ts"
import { computed, onMounted, ref } from "vue"

const { t } = useI18n()
const { systemSettings } = useConfig()
const { activeWallet, wallets, isFiat } = useUserService()

const amount = ref()
const iban = ref("")
const bank = ref()
const depositAddress = ref("")
const processor = ref("bank")

const banks = ref([])
const openDialog = ref(false)
const kycWithdrawalLimit = computed(() => {
  return Math.round(
    activeWallet.value.currency.exchangeRate
    * systemSettings.value.userTransactionLimits.kycLevelOneLimit,
  )
})

const kycWithdrawalLimitText = computed(() => {
  return `Withdrawing more than ${activeWallet.value.currency.sign}${kycWithdrawalLimit.value}
    require additional documents as per licencing regulations.
    If you would like to withdraw less than this amount click ok to adjust the withdrawal amount`
})

onMounted(async () => {
  banks.value = await api.utils.getBankList()
  bank.value = banks.value[0]
})

const minWithdrawalAmount = computed(() => {
  if (isFiat.value) {
    return bank.value?.min ?? 0
  }
  else {
    return Number(
      systemSettings.value.userTransactionLimits.minWithdraw
      * activeWallet.value.currency.exchangeRate,
    ).toFixed(activeWallet.value.currency.places)
  }
})
async function withdraw() {
  if (amount.value > kycWithdrawalLimit.value) {
    openDialog.value = true
    return
  }
  if (isFiat.value) {
    if (amount.value < bank.value.min) {
      toast.warn(
        t("finance.withdrawal_min_amount", {
          amount: activeWallet.value.currency.sign + bank.value.min,
          bank: bank.value.title,
        }),
      )
    }
    else if (amount.value > bank.value.max) {
      toast.warn(
        t("finance.withdrawal_max_amount", {
          amount: activeWallet.value.currency.sign + bank.value.min,
          bank: bank.value.title,
        }),
      )
    }
    else if (!iban.value) {
      toast.warn(t("finance.insert_iban"))
    }
    else {
      if (!amount.value) {
        toast.warn(t("finance.enter_amount"))
        return
      }
      api.users.createWithdrawal({
        provider: "FastlyyPay",
        processor: processor.value,
        amount: +amount.value,
        walletId: +activeWallet.value.id,
        bankName: bank.value.title,
        iban: iban.value,
      })
    }
  }
  else {
    if (!depositAddress.value) {
      toast.warn(t("finance.insert_address"))
    }
    else {
      const params = {
        provider: "Coinspaid",
        address: depositAddress.value,
        amount: +amount.value,
        walletId: +activeWallet.value.id,
      }
      api.users.createWithdrawal(params)
    }
  }
}
</script>

<template>
  <BaseDialog
    v-model="openDialog"
    title="Withdrawal requirements"
    :content="kycWithdrawalLimitText"
  />
  <div class="withdraw-view">
    <h3>{{ t("finance.select_currency") }}</h3>
    <WalletSelect class="wallet-select" :wallets="wallets" mode="currency" />

    <div class="amount-available">
      <p>
        <span>{{ t("finance.available") }}:</span>
        {{ Number(activeWallet.balance).toFixed(activeWallet.currency.places) }}
      </p>
    </div>

    <BaseTextField
      v-model="amount"
      class="withdraw-amount"
      placeholder="0.00"
      :label="t('finance.withdraw_amount')"
    />

    <Transition name="fade" mode="out-in">
      <WalletAddress
        v-if="!isFiat"
        v-model="depositAddress"
        :clearable="true"
        :coin="activeWallet.currency.ticker"
        :label="`${t('finance.insert_here_your')} ${activeWallet.currency.ticker} ${t(
          'account.address',
        )}`"
      />
      <div v-else>
        <BaseTextField v-model="iban" class="iban" placeholder="" :label="t('finance.iban')" />
        <BaseSelect v-model="bank" :options="banks" />
      </div>
    </Transition>

    <BaseButton class="alight wide high" @click="withdraw">
      {{ t("finance.withdraw") }}
    </BaseButton>

    <div class="bottom-container">
      <div class="crypto-rates">
        <div class="small-box">
          {{ t("finance.min_withdrawal") }}:
          {{ minWithdrawalAmount }}
          {{ activeWallet.currency.ticker }}
        </div>
        <div class="small-box">
          1 {{ activeWallet.currency.ticker }} =
          {{ Number(1 / activeWallet.currency.exchangeRate).toFixed(3) }}
          USD
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.withdraw-view {
  margin-top: 2em;

  .wallet-select {
    width: 100%;
    margin-bottom: 25px;
  }

  .amount-available {
    font-size: smaller;
    position: relative;

    p {
      position: absolute;
      right: 0;
      bottom: -2.1em;
    }
  }

  .withdraw-amount {
    margin: 1em 0 2em;
  }

  .bottom-container {
    // display: flex;
    padding: 15px 10px;
    margin-top: 3em;
    border: 4px solid var(--be-small-card-bg);

    .crypto-rates {
      display: flex;
      justify-content: center;
      margin-left: 0px;

      .small-box {
        padding: 8px !important;
        border: 1px solid #777;
        background-color: var(--be-small-card-bg);
        margin: 5px;
        width: 40%;
        text-align: center;
      }
    }

    .note {
      font-size: 0.9em;
      margin: 1em;
    }
  }

  button {
    margin: 2em 0 4em;
  }
}
</style>
