<script setup>
import PdfModal from "@/app/components/modal/PdfModal.vue"
import { useConfig } from "@/app/composables/useConfig"
import { t } from "@/app/composables/useI18n"
import { computed, ref } from "vue"

const props = defineProps({
  bonus: {},
})

const { files } = useConfig()

const bonusTerms = computed(() => {
  return files.value.find(f => f.alias === "bonus-terms" && f.url)
})

const showPdfModal = ref(false)
const pdfLink = ref("")

const bonusRolloverProgress = computed(() => {
  return Math.round((props.bonus?.currentBonusRollover * 100) / props.bonus?.maxBonusRollover)
})

const realRolloverProgress = computed(() => {
  return Math.round(
    (props.bonus?.currentRealFundsRollover * 100) / props.bonus?.maxRealFundsRollover,
  )
})

function handlePdf(link) {
  showPdfModal.value = true
  pdfLink.value = link
}
</script>

<template>
  <div class="status">
    <div v-if="bonus?.maxBonusRollover > 0" class="status-label">
      {{ t("bonus.bonus_rollover") }}
      {{ Math.round(bonus?.maxBonusRollover / bonus?.initialAmount) }}x ({{
        bonus?.maxBonusRollover
      }}
      {{ bonus?.currency?.ticker }})
    </div>
    <div v-if="bonus?.maxBonusRollover > 0">
      <div id="app">
        <div class="shell">
          <div class="bar" :style="{ width: `${bonusRolloverProgress}%` }">
            <span>{{ bonusRolloverProgress }}%</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!bonus">
      <div class="free-bet">
        <div class="free-award">
          {{ t("general.active_bonus_desc") }}
        </div>
      </div>
    </div>
    <div v-if="bonus?.maxRealFundsRollover > 0" class="status-label">
      {{ t("bonus.deposit_rollover") }}
      {{ Math.round(bonus?.maxRealFundsRollover / bonus?.initialAmount) }}x ({{
        bonus?.maxRealFundsRollover
      }}
      {{ bonus?.currency?.ticker }})
    </div>
    <div v-if="bonus?.maxRealFundsRollover > 0" id="app">
      <div class="shell">
        <div class="bar" :style="{ width: `${realRolloverProgress}%` }">
          <span>{{ realRolloverProgress }}%</span>
        </div>
      </div>
    </div>
    <div class="status-description">
      {{ t("bonus.short_rules_description") }}
    </div>
    <a
      v-if="bonusTerms"
      class="rules"
      @click="handlePdf(bonusTerms.url)"
    >
      {{ t("bonus.rules_link_title") }}
    </a>
  </div>
  <PdfModal
    :show="showPdfModal"
    :link="pdfLink"
    @close="() => (showPdfModal = false)"
  />
</template>

<style lang="scss" scoped>
.status {
  margin-top: 16px;
  .status-label {
    font-family: Poppins-reg;
    font-size: 16px;
    font-style: normal;
    margin-bottom: 5px;
  }

  .status-description {
    font-family: Poppins-reg;
    font-size: 16px;
    font-style: normal;
    margin: 20px 0;
  }
}

#app {
  margin-bottom: 16px;
}

.shell {
  height: 34px;
  width: 100%;
  // border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 100px;
  background: rgba(255, 255, 255, 0.1);
  padding: 4px;
}

.bar {
  background: linear-gradient(90deg, #3886ec 0%, #544fe5 100%);

  height: 26px;
  width: 15px;
  border-radius: 100px;
  span {
    float: right;
    padding: 4px 5px;
    color: #fff;
    font-size: 0.7em;
  }
}

.rules {
  color: #3886ec;
  background-clip: text;
  font-family: Poppins-reg;
  font-size: 16px;
  font-style: normal;
  cursor: pointer;
  line-height: normal;
  text-decoration-line: underline;
}
</style>
