import { computed, ref } from "vue"

const bonuses = ref([])

export function useBonuses() {
  async function setBonuses(bonusesList) {
    bonuses.value = bonusesList
  }

  const activeBonus = computed(() => {
    return bonuses.value.filter(bonus => bonus.status === 1)[0] ?? null
  })

  const expiredBonuses = computed(() => {
    return bonuses.value.filter(bonus => bonus.status === 3 || bonus.status === 4)
  })

  const waitingBonuses = computed(() => {
    return bonuses.value.filter(bonus => bonus.status === 0)
  })

  return {
    setBonuses,
    activeBonus,
    expiredBonuses,
    waitingBonuses,
    bonuses,
  }
}
