<script setup>
import BaseIcon from "@/app/components/ui/BaseIcon.vue"
import { useBonuses } from "@/app/composables/useBonus"
import { useI18n } from "@/app/composables/useI18n"
import { onMounted, ref, watch } from "vue"
import History from "../MyBets/CasinoBonusSection/History.vue"
import Pending from "../MyBets/CasinoBonusSection/Pending.vue"
import Rules from "../MyBets/CasinoBonusSection/Rules.vue"
import Status from "../MyBets/CasinoBonusSection/Status.vue"

const props = defineProps({
  show: Boolean,
  bonus: {},
})

const emit = defineEmits(["close"])
const { t } = useI18n()
const { waitingBonuses } = useBonuses()

const selectedBetOption = ref("status")

const tabs = ref([
  { icon: "status", title: t("finance.status"), visible: true },
  { icon: "pending", title: t("finance.pending"), visible: false },
  { icon: "history", title: t("finance.history"), visible: true },
  { icon: "rules", title: t("finance.rules"), visible: true },
])

watch(waitingBonuses, async (newWaitBonuses) => {
  if (newWaitBonuses.length === 0) {
    return
  }

  tabs.value.forEach((tab) => {
    if (tab.icon === "pending") {
      tab.visible = true
    }

    return tab
  })
})
</script>

<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask-bonus">
      <div class="modal-container">
        <div class="modal-detail">
          <div class="close-btn" @click="() => emit('close', false)">
            <div>{{ t("general.close") }}</div>
            <img src="/assets/images/close-modal-icon.svg" alt="">
          </div>

          <div class="modal-body">
            <div class="status-heading">
              {{ bonus?.name }}
            </div>
            <div class="bets">
              <div
                v-for="(tab, i) in tabs"
                :key="i"
                :class="`${tab.visible && 'bet'} ${selectedBetOption === tab.icon && 'active-bet'}`"
                @click="selectedBetOption = tab.icon"
              >
                <BaseIcon
                  v-if="tab.visible"
                  :name="tab.icon"
                  size="18"
                />
                <div v-if="tab.visible">
                  {{ tab.title }}
                </div>
              </div>
            </div>
            <div v-if="selectedBetOption === `status`">
              <Status :bonus="bonus" />
            </div>
            <div v-if="selectedBetOption === `pending`">
              <Pending />
            </div>
            <div v-if="selectedBetOption === `history`">
              <History />
            </div>
            <div v-if="selectedBetOption === `rules`">
              <Rules />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
.bets {
  display: flex;
  gap: 0 16px;
  justify-content: center;
  border-radius: 10px;
  background: var(--be-big-card-bg);
  padding: 10px;
  margin: 10px 0px;
}
.status-heading {
  font-family: Poppins-bold;
  font-size: 24px;
  margin-bottom: 20px;
}

@media screen and (max-width: 500px) {
  .status-heading {
    font-size: 18px;
    padding-top: 6px;
  }
}

.bet {
  color: rgba(255, 255, 255, 0.3);
  width: 191px;
  height: 40px;
  display: flex;
  gap: 0 8px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.active-bet {
  color: var(--be-primary-btn-label);
  background: var(--be-primary-btn);
}

button {
  margin: 10px;
  border: 1px solid #aaa;
  background: none;
  border-radius: 50%;
  padding: 5px 8px;
  outline: none;
  cursor: pointer;
}

h2 {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #cdcdcd;
  color: white;
  line-height: 0px;
  margin: 10px 0 20px;
}

h2 span {
  background: var(--be-big-card-bg);
  font-size: 14px;
  padding: 0 16px;
}

.modal-mask-bonus {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(13, 12, 44, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  transition: opacity 0.3s ease;
}

@media screen and (max-width: 500px) {
  .modal-mask-bonus {
    top: 2;
  }
}

.back-btn {
  color: white;
  position: absolute;
  top: -34px;
  left: 8px;
  display: flex;
  align-items: center;
  gap: 0 5px;
  cursor: pointer;
  .left-icon {
    width: 30px;
  }
}

.close-btn {
  color: white;
  position: absolute;
  top: -32px;
  right: 3px;
  display: flex;
  gap: 0 5px;
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .close-btn {
    top: -37px;
  }
}

.modal-container {
  width: 600px;
  margin: auto;
  padding: 0px 20px 20px 20px;

  height: 100%;
  overflow: auto;
  // background: var(--be-bg);

  transition: all 0.3s ease;
  position: relative;
  display: flex;
}

@media screen and (max-width: 500px) {
  .modal-container {
    padding: 0 16px 16px 16px;
  }
}

.modal-detail {
  background: var(--be-bg);
  padding: 20px 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  margin-top: 13px;
  border-radius: 20px;
  // margin: auto;
  margin: 100px 0 auto 0;
  position: relative;
  width: 100%;
}

@media screen and (max-width: 500px) {
  .modal-detail {
    margin: 111px auto auto auto;
    height: 558px;
    padding: 0px;
  }
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
  border-radius: 8px;
  background: var(--be-big-card-bg);
}

.modal-body {
  border-radius: 8px;
  // background: var(--be-big-card-bg);
  padding: 10px;

  .login-view {
    .forgot-password {
      font-size: 14px;
      margin-bottom: 20px;
      display: flex;
      justify-content: end;
      cursor: pointer;
      color: #3886ec;
      font-family: "Poppins";

      // transition: 0.4s all ease-in;
      // opacity: 0.6;
      &:hover {
        opacity: 1;
      }
    }

    .login-button {
      margin-top: 16px;
      // height: 3em;
    }

    .dont-have-account {
      font-size: 14px;
      margin-bottom: 10px;
      text-align: center;
    }

    .login-now {
      text-align: center;
      color: var(--be-primary-btn);
      cursor: pointer;
    }

    .register-button {
      margin: 1em 0 3em;
      // height: 3em;
    }

    .dont-have-account {
      font-size: 0.9em;
      margin-bottom: 10px;
    }
  }
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
