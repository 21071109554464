<script setup>
import { useBonuses } from "@/app/composables/useBonus"
import { useCurrencies } from "@/app/composables/useCurrencies"
import { t } from "@/app/composables/useI18n"
import moment from "moment"

const { waitingBonuses } = useBonuses()
const { getIconByTicker } = useCurrencies()
</script>

<template>
  <div>
    <div class="col-names">
      <div class="per-25">
        {{ t("finance.bonus") }}
      </div>
      <div class="per-25">
        {{ t("finance.currency") }}
      </div>
      <div class="per-25">
        {{ t("finance.expiry") }}
      </div>
    </div>
    <div class="recs">
      <div
        v-for="(bonus, index) in waitingBonuses"
        :key="index"
        :class="`rec ${index % 2 ? '' : 'odd'}`"
      >
        <div class="per-25">
          {{ bonus.initialAmount }}
        </div>
        <div class="per-25 currency">
          <img :src="getIconByTicker(bonus?.currency?.ticker)" alt="" class="currency-icon">
          <div>{{ bonus?.currency?.ticker }}</div>
        </div>
        <div v-if="bonus?.expirationDate !== null && bonus?.expirationDate !== ''" class="per-25">
          {{ moment(bonus?.expirationDate).format("DD/MM/YYYY") }}
        </div>
        <div v-else class="per-25">
          ---
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.currency-icon {
  width: 16px;
  height: 21px;
}

.col-names {
  display: flex;
  padding: 0 16px;
  font-size: 16px;
  font-family: Poppins-med;
  margin-top: 24px;
}

@media screen and (max-width: 500px) {
  .col-names {
    font-size: 14px;
  }
}

.per-25 {
  width: 33%;
}
.recs {
  margin-top: 14px;
}

.rec {
  display: flex;
  padding: 16px;
  font-size: 16px;
  font-family: Poppins-med;
}

@media screen and (max-width: 500px) {
  .rec {
    font-size: 14px;
  }
}
.odd {
  border-radius: 5px;
  background: var(--be-big-card-bg);
}

.currency {
  display: flex;
  gap: 8px;
}
</style>
