<script setup>
import BaseSlider from "@/app/components/ui/BaseSlider.vue"
import { onBeforeUnmount, onMounted, ref } from "vue"
import { useBetStore } from "../../../store"
import PdfModal from "./modal/PdfModal.vue"

defineProps({
  imgs: {
    type: Object,
  },
})
const store = useBetStore()
const screenWidth = ref(window.innerWidth)

function handleResize() {
  screenWidth.value = window.innerWidth
}

onMounted(() => {
  window.addEventListener("resize", handleResize)
  handleResize() // Initial check
})

onBeforeUnmount(() => {
  window.removeEventListener("resize", handleResize)
})

const pdfLink = ref("")
const showPdfModal = ref(false)

function handlePdf(fileUrl) {
  console.log("fileUrl", fileUrl)
  if (fileUrl) {
    showPdfModal.value = true
    pdfLink.value = fileUrl
  }
}
</script>

<template>
  <div v-if="store.isShow" class="banner">
    <BaseSlider :slides="imgs" @slide-click="handlePdf" />
  </div>
  <PdfModal
    class="pdf-modal"
    :show="showPdfModal"
    :link="pdfLink"
    @close="() => (showPdfModal = false)"
  />
</template>

<style lang="scss" scoped>
// banner................
.pdf-modal {
  margin-top: 50px;
}
.banner-img {
  width: 100%;
}
.button-lab {
  font-size: 10px;
  font-weight: 400;
  color: #fff;
  font-family: Montserrat;
  font-style: normal;
  line-height: 150%;
  letter-spacing: -0.9px;
}
.banner-btn {
  border-radius: 10px;
  background: #ed8730;
  box-shadow: 6px 6px 0px 0px #ba661e;
  height: 69px;
  padding: 0 30px;
  border: none;
  color: white;
  font-weight: 700;
  font-size: 24px;
  margin-top: 32px;
}

.banner-img {
  // height: 256px;
  width: 100%;
}

.banner {
  position: relative;

  .banner-assets {
    position: absolute;
    top: 30px;
    left: 50px;
  }
}

.banner-h1 {
  color: var(--White, #fff);
  font-family: Montserrat;
  font-size: 64px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: -1px;
}

.banner-h2 {
  color: #fff;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.9px;
}

@media screen and (max-width: 500px) {
  .banner {
    .banner-assets {
      top: 9px;
      left: 10px;
    }
  }
  .banner-img {
    height: auto;
  }
  .banner-h1 {
    font-size: 14px;
  }

  .banner-h2 {
    font-size: 10px;
    font-weight: 400;
    width: 73%;
    min-width: 252px;
  }

  .banner-btn {
    font-size: 12px;
    padding: 0 19px;
    height: 27px;
    border-radius: 4.59px;
    background: #ed8730;

    box-shadow: 1.721px 2.295px 0px 0px #ba661e;
  }
}
</style>
